<script setup lang="ts">
const nome = ref("");
const email = ref("");
const sended = ref(false);

const submit = (e: Event) => {
  e.preventDefault();
  fetch("https://api.xmondo.com.br/api/sendmail/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      client: "livro",
      name: nome.value,
      email: email.value,
    }),
  });

  sended.value = true;
};
</script>
<template>
  <UtilsSection
    id="subscribe-section"
    container-class="px-0 md:px-8 pt-0"
    class="px-0 md:px-14"
  >
    <div
      class="bg-[url(/images/backgrounds/blue-bg.jpg)] rounded-4xl flex bg-cover flex-col md:flex-row pb-8 md:pb-0"
      data-aos="fade-up"
    >
      <div class="pt-10 flex-1 flex items-center justify-center">
        <img
          src="/images/elements/single-book-a-arte-da-influencia-livro-v2.png"
        />
      </div>
      <div
        class="flex items-center justify-center flex-1 md:pr-14 px-5 md:px-0"
      >
        <div
          class="bg-[#035DD3]/20 rounded-xl border-[#035DD3]/60 border-4 text-gray-100"
        >
          <div
            class="bg-gradient-to-tr from-[#FFFFFF]/20 via-[#035DD3]/40 to-[#FFFFFF]/20/20 rounded-[9px] px-5 py-8 w-full"
          >
            <form @submit="submit">
              <UtilsTitle
                as="h4"
                class="text-gray-100 text-4xl font border-b pb-3 mb-3 border-[#035DD3]/30"
              >
                {{ $t("subscribe.title-print") }}
              </UtilsTitle>
              <p class="font-extralight">
                {{ $t("subscribe.description") }}
              </p>
              <div
                v-if="sended"
                class="px-5 py-8 my-4 text-center bg-white/10 rounded-lg flex flex-col gap-5 items-center justify-center"
              >
                <Icon name="fa:check" class="text-green-500 text-2xl" />
                <p>
                  {{ $t("subscribe.sended") }}
                </p>
              </div>
              <div class="flex flex-col gap-3 mt-3" v-if="!sended">
                <Input
                  :placeholder="$t('subscribe.name')"
                  v-model="nome"
                  required
                />
                <Input
                  :placeholder="$t('subscribe.email')"
                  v-model="email"
                  type="email"
                  required
                />
              </div>
              <div
                class="mt-5 flex items-center justify-center gap-5 relative"
                v-if="!sended"
              >
                <div class="right-pulse-anim">
                  <img
                    src="/images/elements/right-arrow.png"
                    class="animate-pulse"
                  />
                </div>
                <UtilsButton variant="red" class="py-1">
                  {{ $t("subscribe.button") }}
                </UtilsButton>
                <img
                  src="/images/elements/hand-click.png"
                  class="handclick-anim absolute -bottom-16 right-8 pointer-events-none"
                />
              </div>
              <p
                class="text-sm text-center text-gray-100/60 font-extralight mt-5 leading-none"
              >
                {{ $t("subscribe.privacy") }} hello@xmondo.com.br
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </UtilsSection>
</template>
