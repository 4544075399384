<script setup lang="ts"></script>
<template>
  <UtilsSection
    container-class="flex flex-col items-center"
    class="bg-gradient-to-b from-[#F1FBFE] to-[#DFF0FF] from-[50%]"
  >
    <UtilsTitle as="h4" class="text-center" data-aos="fade-up">
      {{ $t("conquer.title") }}
    </UtilsTitle>
    <UtilsTitle as="h2" class="text-center" data-aos="fade-up">
      {{ $t("conquer.subtitle") }}
    </UtilsTitle>
    <UtilsSeparator data-aos="fade-up" />
    <UtilsSubtitle class="my-5 text-center" data-aos="fade-up">
      {{ $t("conquer.description") }}
    </UtilsSubtitle>

    <div
      class="grid md:grid-cols-2 w-full gap-10 [&>div]:flex [&>div]:gap-2 mt-10"
    >
      <div
        data-aos="fade-up"
        class="flex md:flex-row px-10 md:px-0 flex-col items-center text-center md:items-start md:text-left bg-white rounded-2xl overflow-hidden shadow-xl"
      >
        <div class="md:w-[40%]">
          <img src="/gifs/trophy.gif" class="w-full"  />
        </div>
        <div class="py-5 md:pr-5 flex-1">
          <strong>
            {{ $t("conquer.card1.title") }}
            :</strong
          >
          {{ $t("conquer.card1.description") }}
        </div>
      </div>
      <div
        data-aos="fade-up"
        class="flex md:flex-row px-10 md:px-0 flex-col items-center text-center md:items-start md:text-left bg-white rounded-2xl overflow-hidden shadow-xl"
      >
        <div class="md:w-[40%]">
          <img src="/gifs/rocket.gif" class="w-full" />
        </div>
        <div class="py-5 md:pr-5 flex-1">
          <strong>
            {{ $t("conquer.card2.title") }}
            :</strong
          >
          {{ $t("conquer.card2.description") }}
        </div>
      </div>
      <div
        data-aos="fade-up"
        class="flex md:flex-row flex-col px-10 md:px-0 items-center text-center md:items-start md:text-left bg-white rounded-2xl overflow-hidden shadow-xl"
      >
        <div class="md:w-[40%]">
          <img src="/gifs/hook.gif" class="w-full" />
        </div>
        <div class="py-5 md:pr-5 flex-1">
          <strong>
            {{ $t("conquer.card3.title") }}
            :</strong
          >
          {{ $t("conquer.card3.description") }}
        </div>
      </div>
      <div
        data-aos="fade-up"
        class="flex md:flex-row px-10 md:px-0 flex-col items-center text-center md:items-start md:text-left bg-white rounded-2xl overflow-hidden shadow-xl"
      >
        <div class="md:w-[40%]">
          <img src="/gifs/saturn.gif" class="w-full" />
        </div>
        <div class="py-5 md:pr-5 flex-1">
          <strong>
            {{ $t("conquer.card4.title") }}
            :</strong
          >
          {{ $t("conquer.card4.description") }}
        </div>
      </div>
    </div>
    <div
      data-aos="fade-up"
      class="bg-gradient-to-tr from-[#3A94FE] to-[#27C9F4] text-[#F9F9F9] px-10 py-6 rounded-3xl text-center mt-10 text-lg"
    >
      <strong class="font-bold">"{{ $t("book-title") }}"</strong>
      {{ $t("conquer.footer") }}
    </div>
  </UtilsSection>
</template>
