<script lang="ts" setup>
import StarIcon from "~/assets/icons/star.svg";
import { cn } from "~/lib/utils";

const props = defineProps<{
  text: string;
  name: string;
  maxheight?: boolean;
  userImage?: string;
}>();
</script>
<template>
  <Dialog>
    <DialogTrigger class="text-left h-full">
      <div
        :class="cn('group cursor-pointer', maxheight && 'flex flex-col h-full')"
      >
        <div class="mb-5 flex gap-1" data-aos="fade-up">
          <StarIcon :filled="true" :font-controlled="false" class="w-5" />
          <StarIcon :filled="true" :font-controlled="false" class="w-5" />
          <StarIcon :filled="true" :font-controlled="false" class="w-5" />
          <StarIcon :filled="true" :font-controlled="false" class="w-5" />
          <StarIcon :filled="true" :font-controlled="false" class="w-5" />
        </div>

        <div
          
          :class="
            cn(
              'rounded-lg bg-[linear-gradient(to_bottom_right,#27F4E8_0%,#27F4E820_50%,#EE382477_100%)] shadow-lg css-selector',
              maxheight && 'flex-1'
            )
          "
        >
          <div
            :class="
              cn(
                'overflow-hidden relative px-5 py-7 border-2 border-transparent rounded-lg',
                maxheight && 'h-full'
              )
            "
          >
            <div
              class="absolute bg-white inset-0 group-hover:bg-white/80 transition-all"
            ></div>
            <div
              class="bg-gradient-to-r from-transparent via-white/40 to-transparent w-28 h-[140%] absolute z-20 top-[-20%] rotate-45 left-[-20%] -translate-x-1/2 -translate-y-1/2 group-hover:left-[120%] group-hover:top-[120%] transition-all duration-1000 fill-mode-forwards"
            ></div>
            <div
              class="absolute -top-52 -right-52 h-96 w-96 bg-[radial-gradient(circle,rgba(24,155,230,0.10)_0%,rgba(255,255,255,0.00)_50%)]"
            ></div>
            <div
              class="absolute -bottom-52 -left-52 h-96 w-96 bg-[radial-gradient(circle,rgba(238,56,36,0.10)_0%,rgba(255,255,255,0.00)_50%)]"
            ></div>
            <div class="z-10 relative flex flex-col h-full">
              <div class="flex-1">
                <div class="line-clamp-6">
                  {{ text }}
                </div>
                <span class="underline text-primary-foreground">Leia mais</span>
              </div>
              <div class="mt-5 text-secondary-foreground flex gap-3">
                <div>
                  <img :src="props.userImage" class="w-8 rounded-full" />
                </div>
                <div>
                  {{ name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DialogTrigger>
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Depoimento de {{ name }}</DialogTitle>
        <DialogDescription>
          <div class="flex md:flex-row flex-col py-5 gap-5 items-center md:items-start">
            <div>
              <img :src="props.userImage" class="w-20 rounded-full" />
            </div>
            <div class="flex-1">
              <p>
                "{{ text }}"
              </p>
            </div>
          </div>
        </DialogDescription>
      </DialogHeader>
    </DialogContent>
  </Dialog>
</template>
<style scoped>
.css-selector {
  -webkit-animation: gradient-animation 1s ease infinite;
  -moz-animation: gradient-animation 1s ease infinite;
  animation: gradient-animation 1s ease infinite;
}

@-webkit-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
