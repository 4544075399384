<script setup lang="ts">
import { Primitive } from "radix-vue";
import { cn } from "~/lib/utils";

const props = withDefaults(
  defineProps<{
    class?: string,
    as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  }>(),
  {
    as: "h1",
  }
);
</script>

<template>
  <Primitive
    :as="as"
    :class="
      cn(
        'font-changa text-primary-foreground',
        as === 'h1' && 'text-4xl font-bold',
        as === 'h2' && 'text-5xl font-bold mb-5',
        as == 'h4' && 'text-xl font-medium text-secondary-foreground',
        props.class
      )
    "
  >
    <slot />
    <slot name="append" />
  </Primitive>
</template>
