<script setup lang="ts">
import { UseElementBounding } from "@vueuse/components";

interface Emoji {
  name: string;
  top: number;
  left: number;
}

const emojis: Emoji[] = [
  {
    name: "heart1",
    top: 100,
    left: 300,
  },
  {
    name: "heart2",
    top: -300,
    left: -250,
  },
  {
    name: "heart3",
    top: 400,
    left: -250,
  },
  {
    name: "like1",
    top: -150,
    left: -250,
  },
  {
    name: "like1",
    top: 350,
    left: 270,
  },
  {
    name: "like2",
    top: -300,
    left: 230,
  },

  {
    name: "like3",
    top: 200,
    left: -250,
  },
  {
    name: "like3",
    top: 200,
    left: 250,
  },
  {
    name: "smile1",
    top: -140,
    left: 290,
  },
  {
    name: "smile2",
    top: 0,
    left: 250,
  },
  {
    name: "smile3",
    top: 10,
    left: -300,
  },
];
</script>
<template>
  <UseElementBounding v-slot="{ top, height }">
    <UtilsSection
      class="bg-[url(/images/backgrounds/insta-bg.jpg)] bg-cover bg-[center_center] overflow-hidden"
      container-class="flex items-center justify-center relative"
    >
      <template #default>
        <div
          class="flex flex-col gap-5 items-center justify-center relative z-10"
        >
          <div class="animate-float">
            <div class="animate-pulse">
              <img src="/images/elements/crown.png" data-aos="fade-up" />
            </div>
          </div>
          <img src="/images/elements/instagram-phone.png" data-aos="fade-up" />
          <div class="absolute bottom-14 flex items-center flex-col">
            <img
              src="/images/elements/insta-logo.png"
              class="w-20 mb-3"
              data-aos="fade-up"
            />
            <div class="text-center text-xl font-normal" data-aos="fade-up">
              {{ $t("instagram.follow-us") }}
            </div>
            <div
              class="text-center text-3xl text-[#ED3922] uppercase font-bold mb-4"
              data-aos="fade-up"
            >
              Instagram
            </div>
            <div data-aos="fade-up">
              <UtilsButton
                as="a"
                href="https://www.instagram.com/xmondodigital/"
                target="_blank"
              >
                {{ $t("follow-us") }}
              </UtilsButton>
            </div>
            <img
              src="/images/elements/hand-click.png"
              class="absolute -right-14 -bottom-14 handclick-anim"
            />
          </div>
        </div>
        <div
          class="[&>div]:absolute [&>div]:transition-all [&>div]:duration-1000 absolute w-full flex items-center justify-center"
        >
          <div
            v-for="emoji in emojis"
            :style="{
              ...(top <= height * 0.2
                ? {
                    transform: `translate(${emoji.left}px,${emoji.top}px)`,
                  }
                : {
                    transform: `translate(0px,0px) scale(0.2)`,
                  }),
            }"
          >
            <img
              :src="`/images/elements/emojis/${emoji.name}.png`"
              class="animate-float"
            />
          </div>
        </div>
      </template>
    </UtilsSection>
  </UseElementBounding>
</template>
