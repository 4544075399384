<template>
  <footer class="bg-[#162029]">
    <UtilsSeparator position="left" />
    <!--    <footer class="py-14 max-w-screen-lg container flex flex-col items-center">
      <div class="text-[#98ADC6] text-center text-3xl font-normal">
        Ficou com alguma dúvida?
      </div>
      <div class="flex md:flex-row flex-col gap-5 justify-center mt-5 max-w-80 md:max-w-max">
        <UtilsButton bordered>Termos e Condições</UtilsButton>
        <UtilsButton bordered>Política de Privacidade</UtilsButton>
      </div>
    </footer>
    <UtilsSeparator position="right" /> -->
    <div class="text-center max-w-screen-lg container pt-5 pb-2">
      {{ $t("footer.contact") }}:
      <a href="mailto:hello@xmondo.com.br" class="text-gray-300"
        >hello@xmondo.com.br</a
      >
    </div>
    <div
      class="flex md:flex-row flex-col pb-5 items-center gap-2 justify-center max-w-screen-lg container text-center"
    >
      <span> {{ $t("footer.copy") }}: </span>
      <a href="https://xmondo.com.br/home" target="_blank" class="inline-block">
        <img src="/images/elements/xmondo-logo.png" class="max-w-28 w-full" />
      </a>
      <span>@ 2023. All Rights Reserved</span>
    </div>
  </footer>
</template>
